<template>
	<div>
		<!-- Filters -->
		<performances-list-filters
			v-if="isOptionsLoaded"
			:options="options"
			:states-filter.sync="statesFilter"
			:business-areas-filter.sync="businessAreasFilter"
			:month-filter.sync="monthFilter"
			:year-filter.sync="yearFilter"
		/>

		<performances-import-file
			:is-importing-performances-sidebar-active.sync="isImportingPerformancesSidebarActive"
			:year-filter.sync="yearFilter"
			:month-filter.sync="monthFilter"
			@refetch-data="refetchData"
		/>

		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0"
		>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
					>
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>

					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto"
					>
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar proveedor"
								trim
							/>

							<b-button
								variant="success"
								@click="isImportingPerformancesSidebarActive = true"
							>
								<span class="text-nowrap">Importar Desempeños</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>

			<b-table
				ref="refPerformanceListTable"
				class="position-relative table-performances"
				:style="'height: inherit'"
				:items="fetchPerformances"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #table-busy>
					<div class="text-center text-primary my-2">
						<b-spinner class="align-middle mb-1"></b-spinner>
						<div>
							<b>CARGANDO</b>
						</div>
					</div>
				</template>

				<template #head(security)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(productivity)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(labor_risk)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(colpa)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(financial_risk)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(rotation)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(penalities)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(calibration)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(cycle_date)="data">
					<b-link
						v-if="!data.item.locked"
						:to="{ name: 'performances-evaluate', params: { id: data.item.id } }"
						class="font-weight-bold d-block text-nowrap"
					>
						<b-badge variant="light-info">{{ data.item.month }}</b-badge>
					</b-link>
					<b-badge
						v-else
						variant="light-secondary"
					>
						<feather-icon icon="LockIcon" />
						{{ data.item.month }}
					</b-badge>
				</template>

				<template #cell(state_name)="data">
					<b-badge :variant="data.item.state_color">{{ data.item.state_name }}</b-badge>
				</template>

				<template #cell(provider_name)="data">
					<b-link
						:to="{ name: 'providers-edit', params: { id: data.item.provider_id } }"
						class="font-weight-bold d-block text-nowrap"
						style="color: inherit;"
					>{{ data.item.provider_name }}</b-link>
				</template>

				<template #cell(security)="data">
					<div class="text-nowrap">
						{{ data.item.security ? parseFloat(data.item.security).toFixed(2) : '-' }}
						<feather-icon
							v-if="data.item.security"
							:id="`security-${data.item.id}`"
							v-b-tooltip.hover.top="`${ data.item.security_level === BAJO ? 'Desempeño Bajo' : data.item.security_level === ALTO ? 'Desempeño Alto' : 'Desempeño Medio' }`"
							:icon="data.item.security_level === BAJO ? 'TrendingDownIcon' : data.item.security_level === ALTO ? 'TrendingUpIcon' : 'ActivityIcon'"
							class="align-middle ml-1"
							:class="data.item.security_level === BAJO ? 'text-danger' : data.item.security_level === ALTO ? 'text-success' : 'text-warning'"
						/>
					</div>
				</template>

				<template #cell(productivity)="data">
					<div class="text-nowrap">
						{{ data.item.productivity ? parseFloat(data.item.productivity).toFixed(2) : '-' }}
						<feather-icon
							v-if="data.item.productivity"
							:id="`productivity-${data.item.id}`"
							v-b-tooltip.hover.top="`${ data.item.productivity_level === BAJO ? 'Desempeño Bajo' : data.item.productivity_level === ALTO ? 'Desempeño Alto' : 'Desempeño Medio' }`"
							:icon="data.item.productivity_level === BAJO ? 'TrendingDownIcon' : data.item.productivity_level === ALTO ? 'TrendingUpIcon' : 'ActivityIcon'"
							class="align-middle ml-1"
							:class="data.item.productivity_level === BAJO ? 'text-danger' : data.item.productivity_level === ALTO ? 'text-success' : 'text-warning'"
						/>
					</div>
				</template>

				<template #cell(labor_risk)="data">
					<div class="text-nowrap">
						{{ data.item.labor_risk ? parseFloat(data.item.labor_risk).toFixed(2) : '-'}}
						<feather-icon
							v-if="data.item.labor_risk"
							:id="`labor-risk-${data.item.id}`"
							v-b-tooltip.hover.top="`${ data.item.labor_risk_level === BAJO ? 'Desempeño Bajo' : data.item.labor_risk_level === ALTO ? 'Desempeño Alto' : 'Desempeño Medio' }`"
							:icon="data.item.labor_risk_level === BAJO ? 'TrendingDownIcon' : data.item.labor_risk_level === ALTO ? 'TrendingUpIcon' : 'ActivityIcon'"
							class="align-middle ml-1"
							:class="data.item.labor_risk_level === BAJO ? 'text-danger' : data.item.labor_risk_level === ALTO ? 'text-success' : 'text-warning'"
						/>
					</div>
				</template>

				<template #cell(calibration)="data">
					<div
						class="text-nowrap"
					>{{ data.item.calibration ? 'SÍ' : data.item.state_id === 2 && !data.item.calibration ? 'NO' : '-'}}</div>
				</template>

				<template #cell(performance_name)="data">
					<b-badge :variant="data.item.performance_color">{{ data.item.performance_name || '-' }}</b-badge>
				</template>

				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL"
					>
						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item
							v-if="data.item.file_url"
							:href="data.item.file_url"
						>
							<feather-icon icon="DownloadIcon" />
							<span class="align-middle ml-50">Descargar Carta</span>
						</b-dropdown-item>

						<b-dropdown-divider v-if="data.item.file_url" />

						<b-dropdown-item :to="{ name: 'performances-evaluate', params: { id: data.item.id } }">
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Evaluar</span>
						</b-dropdown-item>

						<b-dropdown-item
							v-if="data.item.performance_level_id"
							:to="{ name: 'performances-document', params: { id: data.item.id } }"
						>
							<feather-icon icon="FileTextIcon" />
							<span class="align-middle ml-50">Generar Documento</span>
						</b-dropdown-item>

						<b-dropdown-item
							@click="openCommentModal(true, data.item)"
							v-if="data.item.security_level !== ALTO || data.item.productivity_level !== ALTO  || data.item.labor_risk_level !== ALTO"
						>
							<feather-icon icon="MessageSquareIcon" />
							<span class="align-middle ml-50">Comentarios por Desempeño Bajo</span>
						</b-dropdown-item>

						<b-dropdown-item
							@click="openCommentModal(false, data.item)"
							v-if="data.item.performance_level_id === REDUCIDO"
						>
							<feather-icon icon="MessageSquareIcon" />
							<span class="align-middle ml-50">Comentarios por Desempeño Reducido</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>

			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span
							class="text-muted"
						>Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalPerformances"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>

		<b-modal
			ref="refCommentModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			cancel-title="Cerrar"
			centered
			:title="`COMENTARIOS POR ${ isPerformanceReduced ? 'DESEMPEÑO REDUCIDO' : 'DESEMPEÑO BAJO' }`"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
			hide-footer
			size="lg"
		>
			<validation-observer
				#default="{ handleSubmit }"
				ref="refCommentForm"
			>
				<b-form @submit.prevent="handleSubmit(saveCommentModal)">
					<b-card
						footer-tag="footer"
						class="mb-0"
					>
						<b-row>
							<b-col
								cols="12"
								class="mb-2"
								v-if="isPerformanceReduced && securityLevel !== ALTO"
							>
								<label for="reduced_security">
									<strong>Seguridad</strong>
								</label>
								<b-form-textarea
									id="reduced_security"
									v-model="commentData.reduced_security"
									placeholder="Comente aquí ..."
									rows="4"
								/>
							</b-col>

							<b-col
								cols="12"
								class="mb-2"
								v-if="isPerformanceReduced && productivityLevel !== ALTO"
							>
								<label for="reduced_productivity">
									<strong>Productividad</strong>
								</label>
								<b-form-textarea
									id="reduced_productivity"
									v-model="commentData.reduced_productivity"
									placeholder="Comente aquí ..."
									rows="4"
								/>
							</b-col>
							<b-col
								cols="12"
								class="mb-2"
								v-if="!isPerformanceReduced && securityLevel !== ALTO"
							>
								<label for="security">
									<strong>Seguridad</strong>
								</label>
								<b-form-textarea
									id="security"
									v-model="commentData.security"
									placeholder="Comente aquí ..."
									rows="4"
								/>
							</b-col>
							<b-col
								cols="12"
								class="mb-2"
								v-if="!isPerformanceReduced && productivityLevel !== ALTO"
							>
								<label for="productivity">
									<strong>Productividad</strong>
								</label>
								<b-form-group>
									<b-form-textarea
										id="productivity"
										v-model="commentData.productivity"
										placeholder="Comente aquí ..."
										rows="4"
									/>
								</b-form-group>
							</b-col>

							<b-col
								cols="12"
								class="mb-2"
								v-if="!isPerformanceReduced && laborRiskLevel !== ALTO"
							>
								<label for="labor">
									<strong>Riesgo Laboral</strong>
								</label>
								<b-form-group>
									<b-form-textarea
										id="labor"
										v-model="commentData.labor"
										placeholder="Comente aquí ..."
										rows="4"
									/>
								</b-form-group>
							</b-col>
						</b-row>

						<template #footer>
							<b-col class="d-flex flex-column flex-sm-row justify-content-sm-end">
								<!-- BUTTON SUBMIT -->
								<b-button-loading
									text="GUARDAR CAMBIOS"
									type="submit"
									class="order-sm-2 order-1 ml-sm-2 ml-0"
									:processing="processing"
								/>

								<b-button
									v-ripple.400="'rgba(186, 191, 199, 0.15)'"
									type="button"
									variant="outline-secondary"
									class="ml-2"
									@click="refCommentModal.hide()"
								>CERRAR</b-button>
							</b-col>
						</template>
					</b-card>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import useNotifications from "@notifications"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import { formatStringToDate, formatCurrency } from "@core/utils/filter"
import { PerformanceLevels, IndexLevels } from "@core/utils/data"
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import PerformancesListFilters from "./PerformancesListFilters.vue"
import performanceStoreModule from "../performanceStoreModule"
import PerformancesImportFile from "./PerformancesImportFile.vue"
import useCriticalitiesList from "./usePerformancesList"
import Ripple from "vue-ripple-directive"
import { avatarText } from "@core/utils/filter"
import VueSweetalert2 from "vue-sweetalert2"
import { VBTooltip } from "bootstrap-vue"
import vSelect from "vue-select"
import store from "@/store"
import axios from "@axios"
import Vue from "vue"
import "animate.css"

Vue.use(VueSweetalert2)

export default {
	components: {
		BButtonLoading,
		ValidationProvider,
		ValidationObserver,
		PerformancesListFilters,
		PerformancesImportFile,

		vSelect
	},
	directives: {
		"b-tooltip": VBTooltip,
		Ripple
	},
	setup() {
		// USE TOAST
		const { swalNotification, toastNotification } = useNotifications()
		const { REDUCIDO } = PerformanceLevels
		const { BAJO, MEDIO, ALTO } = IndexLevels

		// REFS
		const options = ref({})
		const refCommentForm = ref(null)
		const refCommentModal = ref(null)
		const isOptionsLoaded = ref(false)
		const processing = ref(false)
		const isPerformanceReduced = ref(false)
		const productivityLevel = ref(null)
		const securityLevel = ref(null)
		const laborRiskLevel = ref(null)
		const idPerformanceComment = ref(null)
		const commentData = ref({
			reduced_productivity: null,
			reduced_security: null,
			security: null,
			productivity: null,
			labor: null
		})

		const isImportingPerformancesSidebarActive = ref(false)

		const PERFORMANCE_APP_STORE_MODULE_NAME = "app-performance"

		// REGISTER MODULE
		if (!store.hasModule(PERFORMANCE_APP_STORE_MODULE_NAME))
			store.registerModule(
				PERFORMANCE_APP_STORE_MODULE_NAME,
				performanceStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(PERFORMANCE_APP_STORE_MODULE_NAME))
				store.unregisterModule(PERFORMANCE_APP_STORE_MODULE_NAME)
		})

		// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
		onBeforeMount(() => {
			getSelectFilters()
		})

		const getSelectFilters = async () => {
			try {
				const [areas, states, months] = await Promise.all([
					axios.get("/selectors/business-areas"),
					axios.get("/selectors/states"),
					axios.get("/selectors/months")
				])
				options.value = {
					...areas.data,
					...states.data,
					...months.data
				}
				isOptionsLoaded.value = true
			} catch (error) {
				toastNotification("Error al cargar datos de selección", false)
			}
		}

		const saveCommentModal = async () => {
			try {
				if (!idPerformanceComment.value) {
					toastNotification("No existe registro para actualizar", false)
					return
				}

				processing.value = true

				const payload = { ...commentData.value }
				const response = await axios.put(
					`/performance-comments/${idPerformanceComment.value}`,
					payload
				)
				refetchData()
				toastNotification(response.data.message)
				refCommentModal.value.hide()
			} catch (error) {
				toastNotification(
					error.response.data.message ||
						"Ocurrió un error al actualizar los comentarios",
					false
				)
			} finally {
				processing.value = false
			}
		}

		const openCommentModal = (isIndex = false, data) => {
			isPerformanceReduced.value = !isIndex

			productivityLevel.value = data.productivity_level
			securityLevel.value = data.security_level
			laborRiskLevel.value = data.labor_risk_level

			idPerformanceComment.value = data.performance_comment?.id || null

			commentData.value = {
				reduced_productivity:
					data.performance_comment?.reduced_productivity || null,
				reduced_security: data.performance_comment?.reduced_security || null,
				security: data.performance_comment?.security || null,
				productivity: data.performance_comment?.productivity || null,
				labor: data.performance_comment?.labor || null
			}

			refCommentModal.value.show()
		}

		const {
			fetchPerformances,
			tableColumns,
			perPage,
			currentPage,
			totalPerformances,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refPerformanceListTable,
			refetchData,

			// EXTRA FILTERS
			statesFilter,
			businessAreasFilter,
			monthFilter,
			yearFilter
		} = useCriticalitiesList()

		return {
			options,
			isOptionsLoaded,

			fetchPerformances,
			tableColumns,
			perPage,
			currentPage,
			totalPerformances,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refPerformanceListTable,
			refetchData,
			commentData,
			processing,
			refCommentForm,
			refCommentModal,

			REDUCIDO,
			BAJO,
			MEDIO,
			ALTO,
			isPerformanceReduced,
			productivityLevel,
			securityLevel,
			laborRiskLevel,

			// EXTRA FILTERS
			statesFilter,
			businessAreasFilter,
			monthFilter,
			yearFilter,

			// ACTIONS
			avatarText,

			formatStringToDate,
			formatCurrency,
			saveCommentModal,
			openCommentModal,

			isImportingPerformancesSidebarActive
		}
	}
}
</script>

<style lang="scss" scoped>
.media {
	align-items: center;
}

.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>