<template>
	<b-sidebar
		id="importing-performances-sidebar"
		:visible="isImportingPerformancesSidebarActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		right
		@hidden="resetForm"
		@change="(val) => $emit('update:is-importing-performances-sidebar-active', val)"
	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					<strong>IMPORTAR DESEMPEÑOS (EXCEL)</strong>
				</h5>

				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="hide"
				/>
			</div>

			<b-col>
				<b-button
					@click="downloadTemplate"
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					variant="outline-success"
					size="sm"
					block
					class="mt-1"
				>
					DESCARGAR PLANTILLA
					<feather-icon
						class="cursor-pointer"
						icon="DownloadIcon"
						size="16"
						@click="hide"
					/>
				</b-button>
			</b-col>

			<!-- BODY -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<!-- Form -->
				<b-form
					class="p-2"
					@submit.prevent="handleSubmit(onSubmit)"
					@reset.prevent="resetForm"
				>
					<validation-provider
						#default="{ errors }"
						rules="required"
						name="Fecha de ciclo"
					>
						<b-form-group
							label="Fecha de ciclo"
							label-for="cycle_date"
						>
							<flat-pickr
								id="cycle_date"
								v-model="userData.cycle_date"
								:state="errors.length > 0 ? false : null"
								class="form-control"
								:config="onlyDateConfig"
								placeholder="MM-YYYY"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</b-form-group>
					</validation-provider>

					<!-- Names -->
					<validation-provider
						#default="{ errors }"
						name="archivo"
						rules="required"
					>
						<!-- Avatar -->
						<b-form-group
							label="Archivo"
							label-for="excel-file"
						>
							<b-form-file
								id="excel-file"
								v-model="userData.file"
								:state="errors.length > 0 ? false:null"
								@change="onFileChange"
								accept=".xls, .xlsx"
								placeholder="Elija un archivo o suéltelo aquí ..."
								drop-placeholder="Suelta el archivo aquí ...."
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</b-form-group>
					</validation-provider>

					<!-- Form Actions -->
					<div class="d-flex mt-2">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							class="mr-2 mt-2"
							type="submit"
							:disabled="isLoading"
						>
							<b-spinner
								small
								v-if="isLoading"
							/>
							{{ isLoading ? 'Cargando...' : 'IMPORTAR' }}
						</b-button>
						<b-button
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
							class="mr-2 mt-2"
							type="button"
							variant="outline-secondary"
							:disabled="isLoading"
							@click="hide"
						>CANCELAR</b-button>
					</div>
				</b-form>
			</validation-observer>
		</template>
	</b-sidebar>
</template>

<script>
import useNotifications from "@notifications"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { ref, reactive, watch } from "@vue/composition-api"
import { required } from "@validations"
import formValidation from "@core/comp-functions/forms/form-validation"
import Ripple from "vue-ripple-directive"
import flatPickr from "vue-flatpickr-component"
import { Spanish } from "flatpickr/dist/l10n/es"
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index.js"
import "flatpickr/dist/plugins/monthSelect/style.css"
import VueSweetalert2 from "vue-sweetalert2"
import axios from "@axios"
import moment from "moment"
import Vue from "vue"

Vue.use(VueSweetalert2)

export default {
	components: {
		// Form Validation
		ValidationProvider,
		ValidationObserver,
		flatPickr,
		required
	},
	directives: {
		Ripple
	},
	model: {
		prop: "isImportingPerformancesSidebarActive",
		event: "update:is-importing-performances-sidebar-active"
	},
	props: {
		isImportingPerformancesSidebarActive: {
			type: Boolean,
			required: true
		},
		yearFilter: {
			type: [Number, String],
			required: true
		},
		monthFilter: {
			type: [Number, String],
			required: true
		}
	},
	setup(props, { emit }) {
		const { toastNotification } = useNotifications()

		const isLoading = ref(false)
		const blankUserData = {
			cycle_date:
				props.yearFilter && props.monthFilter
					? moment(`${props.yearFilter}-${props.monthFilter}`).format("MM-YYYY")
					: moment().format("MM-YYYY"),
			file: null,
			stateFile: null
		}

		const onlyDateConfig = ref({
			plugins: [
				new monthSelectPlugin({
					shorthand: true,
					dateFormat: "m-Y"
				})
			],
			locale: Spanish
		})

		const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
		const resetuserData = () => {
			userData.value = JSON.parse(JSON.stringify(blankUserData))
		}

		watch(
			() => [props.monthFilter, props.yearFilter],
			([newMonth, newYear]) => {
				if (newMonth && newYear) {
					const newDate = moment(`${newYear}-${newMonth}`).format("MM-YYYY")
					userData.value.cycle_date = newDate
				}
			},
			{ immediate: true }
		)

		const onSubmit = async () => {
			isLoading.value = true

			if (userData.value.file === null) {
				toastNotification("Debe seleccionar un archivo para importar", false)
				return
			}
			if (userData.value.cycle_date === null) {
				toastNotification("Debe seleccionar una fecha para importar", false)
				return
			}

			Vue.swal({
				title: `¿Está seguro(a) de importar registros para la fecha ${userData.value.cycle_date} ?`,
				html: "<small>¡Si no lo está, puede cancelar la acción!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, importar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			})
				.then((result) => {
					if (result.value) {
						const formData = new FormData()
						formData.append("file", userData.value.file)
						formData.append("cycle_date", userData.value.cycle_date)

						axios
							.post("/performances/import-template", formData, {
								headers: { "Content-Type": "multipart/form-data" }
							})
							.then((response) => {
								emit("refetch-data")
								emit("update:is-importing-performances-sidebar-active", false)

								toastNotification(response.data.message)
							})
							.catch((error) => {
								toastNotification(error.response.data.message, false)
							})
							.finally(() => {
								isLoading.value = false
							})
					}
				})
				.finally(() => {
					isLoading.value = false
				})
		}

		const onFileChange = (e) => {
			const file = e.target.files[0]
			userData.value.file = file || null
			/* if (file) {
				userData.value.file = file
								userData.value.stateFile = Boolean(userData.value.file)
			} else {
				userData.value.file = null
				userData.value.stateFile = null
			} */
		}

		const downloadTemplate = async () => {
			try {
				const url = `/performances/download-template?cycle_date=${userData.value.cycle_date}`
				await axios.get(url).then(async (_) => {
					const result = await axios.get(url, {
						responseType: "blob"
					})

					const urlBlob = window.URL.createObjectURL(new Blob([result.data]))
					const link = document.createElement("a")
					link.href = urlBlob
					link.setAttribute(
						"download",
						`plantilla_de_desempeno_${props.yearFilter}${props.monthFilter
							.toString()
							.padStart(2, "0")}.xlsx`
					)
					document.body.appendChild(link)
					link.click()

					document.body.removeChild(link)
					window.URL.revokeObjectURL(urlBlob)
				})
			} catch (error) {
				toastNotification(error.response.data.message, false)
			}
		}

		const { refFormObserver, getValidationState, resetForm } =
			formValidation(resetuserData)

		return {
			userData,
			onFileChange,
			onSubmit,

			refFormObserver,
			getValidationState,
			resetForm,

			isLoading,
			onlyDateConfig,

			downloadTemplate
		}
	}
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>